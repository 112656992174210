$body : (
	font-family: "poppins", sans-serif, 
	font-weight: 300
);

$headings: (
	font-family: "poppins", sans-serif, 
	font-weight: 600
);

$green: #62F1AC;

// Sass
