input,
select,
textarea,
fieldset {
	box-sizing: border-box;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.5rem;
	font-family: inherit;
	// -webkit-appearance: none;
}

.input {
	width: 100%;
	appearance: none;
	font-size: 1.4rem;
	padding: 2px 10px;
	color: #111;
	border: 3px solid #111;
	background: #fafafa;
	box-shadow: 0 0 10px rgba(0,0,0,.3);
}

.dropdown {
	width: 100%;
	appearance: none;
	font-size: 1.4rem;
	padding: 5px 10px;
	color: #111;
	background: #fafafa url("../images/dropdown.svg") no-repeat 95% center;
	border: 3px solid #111;
	border-radius: 0;
	box-shadow: 0 0 10px rgba(0,0,0,.3);
}

label {
	vertical-align: middle;
}

button.login, .login {
	display: inline-block;
	font-size: 2rem;
	background: #111;
	border: 0;
	padding: 10px 30px;
	color: white;
	text-decoration: none;
}

button.deleteAll {
	font-size: 2rem;
	background: #111;
	border: 0;
	padding: 10px 30px;
	color: white;
	transition: all .3s;
	box-shadow: 0 0 10px rgba(0,0,0,.3);
	&:hover {
		background: #E91E63;
	}
}

button.search {
	font-size: 2rem;
	background: #111;
	width: 100%;
	color: white;
	font-weight: 300;
	border: 0;
	padding: 10px 30px;
	box-shadow: 0 0 10px rgba(0,0,0,.3);
	transition: all .8s;
	&:focus {
		outline: 0;
	}
	&:hover {
		background: #333;
	}
}

button.deleteFile {
	border: 0;
	background: #111;
	display: inline-block;
	padding: 10px 30px;
	color: white;
	transition: all .3s;
	&:focus {
		outline: 0;
	}
	&:hover {
		background: #E91E63;
	}
}