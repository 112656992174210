@charset "UTF-8";
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.5rem;
  line-height: 1.428;
  font-family: "poppins";
  sans-serif, font-weight: 300; }

h1 {
  font-size: 3.157em; }

h2 {
  font-size: 1.9em; }

h3 {
  font-size: 1.3em; }

h4 {
  font-size: 1.333em; }

h1, h2, h3, h4, h5, h6 {
  font-family: inherit; }

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  line-height: 1.2;
  letter-spacing: -.04em;
  font-family: "poppins";
  sans-serif, font-weight: 600; }

h1, h2, h3 {
  margin-top: 10px;
  margin-bottom: 10px; }

p {
  font-size: 1em;
  line-height: 1.428; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 10px; }
  ol:last-child, ul:last-child, dl:last-child {
    margin-bottom: 0; }

input,
select,
textarea,
fieldset {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-family: inherit; }

.input {
  width: 100%;
  appearance: none;
  font-size: 1.4rem;
  padding: 2px 10px;
  color: #111;
  border: 3px solid #111;
  background: #fafafa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.dropdown {
  width: 100%;
  appearance: none;
  font-size: 1.4rem;
  padding: 5px 10px;
  color: #111;
  background: #fafafa url("../images/dropdown.svg") no-repeat 95% center;
  border: 3px solid #111;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

label {
  vertical-align: middle; }

button.login, .login {
  display: inline-block;
  font-size: 2rem;
  background: #111;
  border: 0;
  padding: 10px 30px;
  color: white;
  text-decoration: none; }

button.deleteAll {
  font-size: 2rem;
  background: #111;
  border: 0;
  padding: 10px 30px;
  color: white;
  transition: all .3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  button.deleteAll:hover {
    background: #E91E63; }

button.search {
  font-size: 2rem;
  background: #111;
  width: 100%;
  color: white;
  font-weight: 300;
  border: 0;
  padding: 10px 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all .8s; }
  button.search:focus {
    outline: 0; }
  button.search:hover {
    background: #333; }

button.deleteFile {
  border: 0;
  background: #111;
  display: inline-block;
  padding: 10px 30px;
  color: white;
  transition: all .3s; }
  button.deleteFile:focus {
    outline: 0; }
  button.deleteFile:hover {
    background: #E91E63; }

@lost flexbox flex;
body {
  min-height: 100vh;
  background: #fafafa; }

img {
  max-width: 100%; }

.wrapper {
  margin: 0 auto;
  max-width: 980px; }

header.home-intro {
  text-align: center;
  padding: 200px;
  background: #651FFF;
  border-bottom: 30px solid #111;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.3); }
  header.home-intro h1 {
    font-size: 7rem;
    color: #fafafa;
    margin: 0;
    display: inline-block;
    line-height: 1;
    padding: 8px 20px 3px;
    border: 7px solid #111;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  header.home-intro p.lead {
    color: white;
    font-size: 1.8rem; }

section.content {
  padding: 150px 0; }
  section.content .wrapper {
    display: flex; }
  section.content .rightcontent img {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  section.content .leftcontent {
    margin-right: 50px; }
    section.content .leftcontent p {
      line-height: 1.7; }
      section.content .leftcontent p a {
        text-decoration: none;
        color: #651FFF; }

section.faq {
  background: #EEEEEE;
  padding: 150px 0; }
  section.faq .questionContainer {
    margin-bottom: 25px; }
    section.faq .questionContainer p.question {
      font-weight: 700;
      font-size: 1.9rem;
      display: inline-block;
      margin-bottom: .6rem; }
    section.faq .questionContainer p.answer {
      margin-top: 0;
      line-height: 2;
      font-size: 1.6rem; }
      section.faq .questionContainer p.answer a {
        font-weight: 700;
        color: #651FFF;
        text-decoration: none; }

footer.home {
  background: #111;
  padding: 50px 0;
  text-align: center;
  color: #fafafa; }
  footer.home a {
    text-decoration: none;
    color: #651FFF; }

/*===========================================
=            Structural Partials            =
===========================================*/
header.app-header {
  width: 100%;
  top: 0;
  left: 0;
  padding: 15px 25px;
  position: fixed;
  z-index: 2;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.3); }
  header.app-header .intro {
    position: relative; }
    header.app-header .intro h1 {
      margin: 0;
      display: inline-block;
      line-height: 1;
      padding: 8px 20px 3px;
      border: 4px solid black;
      margin-right: 10px; }

.userProfile {
  display: flex;
  align-items: center; }
  .userProfile p {
    font-size: 1.8rem; }
  .userProfile img.profileImage {
    display: block;
    margin-left: 15px; }
  .userProfile .logout {
    display: inline-block;
    margin-left: 15px;
    font-size: 1.4rem;
    text-decoration: none;
    padding: 10px 30px;
    color: white;
    background: #111; }

section.fileDisplay {
  top: 102px;
  height: calc(90vh - 402px);
  position: relative; }

aside.fileControl {
  position: fixed;
  width: 350px;
  height: 100%;
  background: #651FFF;
  top: 100px;
  padding: 20px 25px 150px 25px;
  color: white;
  overflow: auto; }

p.formTitle {
  font-size: 2.5rem;
  font-weight: 700; }

p.fileSize {
  font-size: 1.7rem;
  font-weight: 700; }

.fileTypeList {
  margin-bottom: 20px; }

header.displayInfo {
  margin-bottom: 20px;
  padding: 0 20px 20px;
  border-bottom: 3px solid #111; }

.check-row {
  margin: 10px 0; }
  .check-row label {
    display: inline-block;
    font-size: 1.3rem;
    margin-right: 10px;
    font-weight: 300;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 7px 2px; }
  .check-row input[type=checkbox] {
    appearance: none;
    position: relative; }
    .check-row input[type=checkbox]::before {
      content: "";
      border: 3px solid #111;
      background: #fafafa;
      position: absolute;
      top: -17px;
      left: 0;
      width: 25px;
      height: 25px; }
    .check-row input[type=checkbox]:checked::after {
      content: "×";
      font-size: 3rem;
      position: absolute;
      color: #111;
      top: -26px;
      left: 2px; }
    .check-row input[type=checkbox]:focus {
      outline: 0; }
  .check-row input[type=number] {
    width: 100px; }

.field {
  width: 100%;
  margin: 20px 0; }
  .field label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    display: inline-block;
    padding: 5px 7px 2px;
    font-weight: 300;
    background: rgba(0, 0, 0, 0.7); }

section.fileList {
  margin-left: 350px;
  width: calc(100% - 350px);
  padding: 25px;
  position: relative; }

.noFiles {
  position: absolute;
  width: 450px;
  top: 50px;
  left: calc(50% - 225px);
  height: calc(100vh - 350px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .noFiles .noFiles-display ol {
    text-align: left; }
    .noFiles .noFiles-display ol li {
      margin-bottom: 1rem; }

article.fileCard {
  width: 31%;
  margin: 0 1% 20px 1%;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px; }
  article.fileCard .previewImg {
    height: 179px;
    background: #E0E0E0;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center; }
  article.fileCard h3 {
    word-break: break-all;
    font-weight: 700; }
    article.fileCard h3 a {
      color: #111;
      text-decoration: none; }
  article.fileCard .fileType {
    font-weight: 700;
    color: #E91E63; }

.fileNum {
  text-align: center;
  font-size: 1.8rem; }

.fileContent {
  margin-bottom: 10px; }

pre.fileCode {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.2;
  background: #111;
  padding: 5px; }
  pre.fileCode code {
    color: #fafafa;
    word-break: break-all;
    white-space: pre-wrap; }

p.fileMeta {
  font-size: 1.2rem;
  color: #757575;
  margin: 0; }

p.fileDate {
  margin: 5px 0 0;
  font-size: 1.3rem; }

footer.footerdetails {
  text-align: center;
  margin-top: 25px; }
  footer.footerdetails p {
    margin: .5rem 0 .3rem; }
    footer.footerdetails p a {
      color: #F06292;
      text-decoration: none; }

.removeAll {
  margin-bottom: 2rem; }
