@import "utilities/normalize";
@import "utilities/brand";
@import "utilities/tools";
@import "utilities/typography";
@import "utilities/form";

@lost flexbox flex;

body {
	min-height: 100vh;
	background: #fafafa;
}

img {
	max-width: 100%;
}

.wrapper {
	margin: 0 auto;
	max-width: 980px;
}

header.home-intro {
	text-align: center;
	padding: 200px;
	background: #651FFF;
	border-bottom: 30px solid #111;
	box-shadow: 0 2px 25px rgba(0,0,0,.3);
	h1 {
		font-size: 7rem;
		color: #fafafa;
		margin: 0;
		display: inline-block;
	  line-height: 1;
	  padding: 8px 20px 3px;
	  border: 7px solid #111;
	  box-shadow: 0 0 10px rgba(0,0,0, .1);
	}
	p.lead {
		color: white;
		font-size: 1.8rem;
	}
}

section.content {
	padding: 150px 0;
	.wrapper {
		display: flex;
	}
	.rightcontent img {
		box-shadow: 0 0 10px rgba(0,0,0, .1);
	}
	.leftcontent {
		margin-right: 50px;
		p {
			line-height: 1.7;
			a {
				text-decoration: none;
				color: #651FFF;
			}
		}
	}
}

section.faq {
	background: #EEEEEE;
	padding: 150px 0;
	.questionContainer {
		margin-bottom: 25px;
		p.question {
			font-weight: 700;
			font-size: 1.9rem;
			display: inline-block;
			margin-bottom: .6rem;
		}
		p.answer {
			margin-top: 0;
			line-height: 2;
			font-size: 1.6rem;
			a {
				font-weight: 700;
				color: #651FFF;
				text-decoration: none;
			}
		}
	}
}

footer.home {
	background: #111;
	padding: 50px 0;
	text-align: center;
	color: #fafafa;
	a {
		text-decoration: none;
		color: #651FFF;
	}
}

/*===========================================
=            Structural Partials            =
===========================================*/

header.app-header {
	width: 100%;
	top: 0;
	left: 0;
	padding: 15px 25px;
	position: fixed;
	z-index: 2;
	background: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 2px 25px rgba(0,0,0,.3);
	.intro {
		position: relative;
		h1 {
			margin: 0;
			display: inline-block;
		  line-height: 1;
		  padding: 8px 20px 3px;
		  border: 4px solid black;
		  margin-right: 10px;
		}
	}
}

.userProfile {
	display: flex;
	align-items: center;
	p {
		font-size: 1.8rem;
	}
	img.profileImage {
		display: block;
		margin-left: 15px;
	}
	.logout {
		display: inline-block;
		margin-left: 15px;
		font-size: 1.4rem;
		text-decoration: none;
		padding: 10px 30px;
		color: white;
		background: #111;
	}
}

section.fileDisplay {
	top: 102px;
	height: calc(90vh - 402px);
	position: relative;
}

@import "partials/fileForm";

section.fileList {
	margin-left: 350px;
	width: calc(100% - 350px);
	padding: 25px;
	position: relative;
}

.noFiles {
	position: absolute;
	width: 450px;
	top: 50px;
	left: calc(50% - 225px);
	height: calc(100vh - 350px);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	.noFiles-display {
		ol {
			text-align: left;
			li {
				margin-bottom: 1rem;
			}
		}
	}
}

article.fileCard {
	width: 31%;
	margin: 0 1% 20px 1%;
	background: white;
	box-shadow: 0 0 10px rgba(0,0,0, .1);
	text-align: center;
	padding: 20px;
	.previewImg {
		height: 179px;
		background: #E0E0E0;
		overflow: hidden;
		margin-bottom: 10px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
	}
	h3 {
		word-break: break-all;
		font-weight: 700;
		a {
			color: #111;
			text-decoration: none;
		}
	}
	
	.fileType {
		font-weight: 700;
		color: #E91E63;
	}
}



.fileNum {
	text-align: center;
	font-size: 1.8rem;
}


.fileContent {
	margin-bottom: 10px;
}

pre.fileCode {
	text-align: left;
	font-size: 1.2rem;
	line-height: 1.2;
	background: #111;
	padding: 5px;
	code {
		color: #fafafa;
		word-break: break-all;
		white-space: pre-wrap;
	}
}

p.fileMeta {
	font-size: 1.2rem;
	color: #757575;
	margin: 0;
}
p.fileDate {
	margin: 5px 0 0;
	font-size: 1.3rem;
}

footer.footerdetails {
	text-align: center;
	margin-top: 25px;
	p {
		margin: .5rem 0 .3rem;
		a {
			color: #F06292;
			text-decoration: none;
		}
	}
}

.removeAll {
	margin-bottom: 2rem;
}


